<template>
  <main class="event-detail">

    <v-row :gutters="3" pa-2>
      <v-col style="max-width: 300px !important">
        <div class="sidebar">
          <h3>{{ $t('Tickets') }}</h3>
        </div>
      </v-col>
      <v-col>
        <v-card class="codox-card">
          <codox :event-id="id" :doc-id="id"/>
        </v-card>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import Codox from '@/components/Codox'

export default {
  name: 'events',
  components: { Codox },
  props: {
    id: { required: true }
  }
}
</script>

<style lang="scss">
.event-detail {
  padding: 20px;
  height: 100%;
  display: flex;

  .codox-card {
    padding: 20px;
    height: 100%;
  }
}

</style>
